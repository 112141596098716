import React from 'react';

import Layout from '../components/layout';

const Students = () => (
	<Layout>
		<div>
			Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde nam
			necessitatibus repellat reiciendis, culpa, alias nemo deserunt ipsum
			adipisci minima consequatur rerum? Fugit quis quaerat rem architecto.
			Nihil, autem perspiciatis?
		</div>
	</Layout>
);

export default Students;
